var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-container"},[_c('div',{staticClass:"laboratory-container common-box"},[_c('Header'),_c('div',{staticClass:"laboratory-center"},[_vm._m(0),_c('div',{staticClass:"laboratory-video"},[_c('video',{ref:"video",staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/video/30s.mp4"),"controls":"","muted":"","autoplay":"","preload":"metadata","webkit-playsinline":"true","playsinline":"true","x-webkit-airplay":"allow","x5-video-player-type":"h5","x5-video-player-fullscreen":"false","x5-video-orientation":"landscape"},domProps:{"muted":true}},[_vm._v(" 抱歉，您的浏览器不支持内嵌视频! ")])]),_c('div',{staticClass:"laboratory-descs"},[_vm._v(" Since 2014, we've invested in a series of research and development activities, including studies covering nutrient relationships with aging, weight control, vision protection, gut health, metabolism, heart and cardiovascular health, and more. Our fully functional nutrition lab has been equipped with cutting-edge technology for nearly a decade to support these efforts for our clients and consumers. ")]),_c('div',{staticClass:"laboratory-images"},_vm._l((4),function(item,index){return _c('div',{key:item},[_c('el-image',{attrs:{"src":require('@/assets/images/labs'+(index+1)+'.png'),"preview-src-list":[
            require('@/assets/images/labs1.png'),
            require('@/assets/images/labs2.png'),
            require('@/assets/images/labs3.png'),
            require('@/assets/images/labs4.png'),
          ]}})],1)}),0),_c('div',{staticClass:"laboratory-persons"},[_c('div',{staticClass:"common-titles"},[_vm._v("RESEARCH SCIENTIST TEAM")]),_c('div',{staticClass:"laboratory-persons-list"},_vm._l((_vm.PersonLists),function(item,index){return _c('div',{key:index,staticClass:"laboratory-persons-item"},[_c('img',{staticClass:"laboratory-persons-infoimg",attrs:{"src":item.image,"alt":""}})])}),0)]),_vm._m(1),_c('div',{staticClass:"laboratory-studies"},_vm._l((_vm.StudiesLists),function(item,index){return _c('div',{key:index,class:`laboratory-studies-item${index+1}`},[_c('div',{staticClass:"laboratory-studies-left"},[_c('img',{attrs:{"src":item.picture,"alt":""}})]),_c('div',{staticClass:"laboratory-studies-right"},[_c('p',[_vm._v(_vm._s(item.sepcialName))])])])}),0),_c('div',{staticClass:"major-lists"},_vm._l((_vm.StudiesLists),function(item,index){return _c('div',{key:index,staticClass:"major-items"},[_c('div',{staticClass:"major-items-left"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.desc))])]),_c('div',{staticClass:"major-items-right"},[_c('img',{attrs:{"src":item.picture,"alt":""}})])])}),0)]),_c('BestSellers')],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-page-title"},[_c('div',{staticClass:"detail-page-title-left"},[_c('div',[_vm._v("Our Fully Functional")]),_c('div',{staticClass:"detail-page-second"},[_vm._v("Nutrition Laboratory")])]),_c('div',{staticClass:"detail-page-title-right"},[_c('img',{staticStyle:{"width":"auto","height":"80px"},attrs:{"src":require("@/assets/images/lab-right.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"laboratory-major"},[_c('div',[_vm._v("MAJOR RESEARCH & DEVELOPMENT STUDIES")]),_c('div',[_vm._v("By our fully functional dietary nutrition laboratory")])])
}]

export { render, staticRenderFns }